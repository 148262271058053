<template>
  <div
    class="modal fade"
    :id="`exampleModal-` + modalCrud"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="false"
    v-if="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ? Want To Change This Status</p>
          <div class="text-right">
            <button
              class="btn btn-primary mr-1"
              type="submit"
              @click="updateStatus"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: [
    "modalTitle",
    "modalId",
    "modalCrud",
    "modalStatus",
    "modalChild",
    "modalRole",
  ],
  data() {
    return {
      id: this.modalId,
      crud_id: this.modalCrud,
      data: [],
      form: {
        role_id: this.modalRole,
        menu_child_id: this.modalChild,
      },
      dialog: true,
      status: this.modalStatus,
    };
  },
  mounted() {},
  methods: {
    updateStatus() {
      if (this.status == true) {
        let id = this.crud_id;
        axios
          .delete(this.$store.state.api + "crud_service/" + id, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.dialog = false;
            this.$noty.success("Your Status has been updated!");
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
            window.location.reload();
          });
      } else {
        axios
          .post(this.$store.state.api + "crud_service/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.dialog = false;
            this.$noty.success("Your Status has been updated!");
            window.location.reload();
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
